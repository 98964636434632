import { ElementType } from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import MUILink from '@mui/material/Link';

export const Paragraph = styled(Typography)<{ component?: ElementType }>(
  () => ({
    '&.MuiTypography-root': {
      marginBottom: 20,
      marginTop: 20,
    },
  }),
);

export const Link = styled(MUILink)<{
  component?: ElementType;
}>(({ theme }) => ({
  color: theme.palette.info.main,
}));

export const H1 = styled(Typography)<{ component?: ElementType }>(() => ({
  '&.MuiTypography-root': {
    marginTop: 20,
    marginBottom: 20,
    fontSize: '2.125rem',
  },
}));

export const H2 = styled(Typography)<{ component?: ElementType }>(() => ({
  '&.MuiTypography-root': {
    marginTop: 20,
    marginBottom: 20,
    fontSize: '1.4rem',
  },
}));

export const H3 = styled(Typography)<{ component?: ElementType }>(() => ({
  '&.MuiTypography-root': {
    fontSize: '1.1rem',
    fontWeight: 600,
  },
}));

export const H4 = styled(Typography)<{ component?: ElementType }>(() => ({
  '&.MuiTypography-root': {
    fontSize: '1rem',
    fontWeight: 600,
  },
}));

export const ImageWrap = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '3rem',
  marginTop: '3rem',
}));
